import React, { useEffect, useState, Suspense } from "react";
import {
  Box,
  Skeleton,
  Button,
  IconButton,
  ButtonBase,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useLocation } from "wouter";

import { useLoadImage } from "../../../hooks";

import { convertNotation } from "../../../../outils";

const Zoom = (props) => {
  const { drawerOpen, annotationId } = props;
  const { image, error, loading } = useLoadImage(props);

  const colorFiche = "#a26f9d";
  const colorArchimage = "#6b82ac";
  const colorArchimageDark = "#4e668e";

  const ButtonArchimage = styled(Button)(({ theme }) => ({
    backgroundColor: colorArchimage,
    "&:hover": {
      backgroundColor: colorArchimageDark,
    },
  }));

  const [location, setLocation] = useLocation();
  const [firstLoad, setFirstLoad] = useState(false);
  const img_privee = "/images/img_privee.jpg";
  const img_blank = "/modeles/placeholders/geometry_blank.png";

  const { hex, couche } = convertNotation(props?.listNotation);

  const handleClick = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (drawerOpen) {
      couche && hex && setTimeout(() => setLocation(`/${couche}/${hex}`), 100);
    }

    !drawerOpen ? setTimeout(() => setLocation(""), 600) : null;

    return () => setFirstLoad(false);
  }, [hex, couche, drawerOpen]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-block",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "650px",
          bgcolor: "#212121",
          borderRadius: "4px",
          objectFit: "contain",
          // display: "inline-block",
          //
          // display: "flex",
          // flexWrap: "wrap",
        }}
      >
        {/* <Skeleton variant="rounded" height="100%" /> */}

        {!loading && !error?.status && image ? (
          <img
            src={image}
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: "4px",
            }}
          />
        ) : (
          <Skeleton variant="rounded" height="100%" />
        )}
      </Box>

      <ButtonArchimage
        sx={{
          position: "absolute",
          right: 70,
          bottom: 0,
          margin: 1,
        }}
        variant="contained"
        color="secondary"
        size="small"
        onClick={(e) => handleClick(e, props.fiche)}
        startIcon={<OpenInNewIcon />}
        title="voir la fiche de l'image sur Archimage"
      >
        Archimage
      </ButtonArchimage>

      <ButtonArchimage
        sx={{
          position: "absolute",
          right: 0,
          bottom: 0,
          margin: 1,
        }}
        variant="contained"
        color="secondary"
        size="small"
        onClick={(e) =>
          handleClick(
            e,
            `https://archimage.efa.gr/?r=visionneuse_iiif&id=${props.archimageId}`
          )
        }
        title="voir l'image sur Archimage"
      >
        IIIF
      </ButtonArchimage>
    </Box>
  );
};

export default Zoom;
