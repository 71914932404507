import React, { useState, useEffect, useMemo } from "react";

const useLoadImage = (props) => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  // let annotId = `image-${props.annotationId.image}$_annotation-${props.annotationId.annotation}`;

  // console.log("useLoadImage :", props);

  // let img = useMemo(() => {
  //   return new Image();
  // }, [props.image]);

  useEffect(() => {
    setLoading(true);
    const img = new Image();
    img.src = props.image;
    img.onload = () => {
      setImage(props.image);
      setLoading(false);
      setError({ status: false, message: "image chargée" });
    };

    img.onerror = () => {
      setLoading(false);
      setError({ status: true, message: "probleme de chargement image" });
    };
  }, [props.image]);

  return { image, loading, error };
};

export default useLoadImage;
