import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLocation as useWocation, useRoute as useWoute } from "wouter";
import useHashLocation from "../hooks/useHashLocation";

const TemplatePath = (props) => {
  const { id, title, path, translation } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [loc] = useWocation();
  const [locHash] = useHashLocation();

  const [hovered, setHovered] = useState(false);
  const [selected, setSelected] = useState(false);

  const fill = hovered ? "orange" : selected ? "peru" : "#ffffff33";
  const stroke = hovered ? "orange" : selected ? "peru" : "#cccccc";

  const handleClick = () => {
    navigate(`/${id}`);
  };

  useEffect(() => {
    let path = import.meta.env.VITE_NODE_ENV === "production" ? locHash : loc;
    if (path === `/${id}`) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [loc, locHash, location]);

  const handleMouseOver = (e) => {
    e.stopPropagation();
    setHovered(true);
  };
  const handleMouseOut = (e) => {
    e.stopPropagation();
    setHovered(false);
  };

  return (
    <g
      id={id}
      className="fragments"
      transform={`translate(${translation.x} ${translation.y})`}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <path
        className="fragments"
        id={`path-${id}`}
        fill={fill}
        stroke={stroke}
        d={path}
      />
      <title>{title}</title>
    </g>
  );
};

export default TemplatePath;
