import * as url from "url";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography, Stack } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const Thesaurii = () => {
  let themes = [
    { label: "matéralité", value: "materialite", id: 2 },
    { label: "iconographie", value: "iconographie", id: 1 },
  ];

  const DownloadLink = ({ id }) => {
    const url = `${
      import.meta.env.VITE_OPENTHESO_API
    }/expansion/concept?theso=th653&id=${id}&way=down`;

    return (
      <Button
        variant="outlined"
        size="small"
        startIcon={<DownloadIcon />}
        component={Link}
        to={url}
        title={`à enregistrer dans "/opentheso" et renommer en "th653_id${id}_waydown.rdf"`}
      >
        RDF
      </Button>
    );
  };

  return (
    <div>
      <div id="list">
        <Stack direction="column" spacing={2}>
          {themes.map((item, index) => (
            <Stack
              key={index}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Button
                size="small"
                component={Link}
                to={`/thesaurii/${item.value}`}
              >
                {item.label}
              </Button>

              <DownloadLink id={item.id} />
            </Stack>
          ))}
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="button">Thesaurus complet</Typography>
            <Button
              variant="outlined"
              size="small"
              startIcon={<DownloadIcon />}
              component={Link}
              to={`${
                import.meta.env.VITE_OPENTHESO_API
              }/all/theso?id=th653&format=rdf`}
              title={`à enregistrer dans "/opentheso"  et renommer en "th653.rdf"`}
            >
              RDF
            </Button>
          </Stack>
        </Stack>
      </div>
    </div>
  );
};

export default Thesaurii;
