import React, { useState, useEffect } from "react";
import { Tooltip, Link, Menu, MenuItem } from "@mui/material";
import useDefinitionStore from "../../../../stores/useDefinitionStore";
import useQueryDefinitions from "../../../hooks/useQueryDefinitions";
import useThesoStore from "../../../../stores/useThesoStore";
import { useNavigate } from "react-router-dom";

import { parseExternalUrl, convertNotation } from "../../../../outils";

// import BulleArchimageIIIF from "./bulleArchimageIIIF";

const TooltipAnnotation = (props) => {
  const { id, text, couche, hex } = props;

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [related, setRelated] = useState(null);

  const currentCouche = useDefinitionStore((state) => state.currentCouche);
  const setCurrentCouche = useDefinitionStore(
    (state) => state.setCurrentCouche
  );
  const setActiveColor = useDefinitionStore((state) => state.setActiveColor);
  const setPreviousColor = useDefinitionStore(
    (state) => state.setPreviousColor
  );

  const activeColor = useDefinitionStore((state) => state.activeColor);
  const previousColor = useDefinitionStore((state) => state.previousColor);

  const mouseActive = useDefinitionStore((state) => state.mouseActive);
  const setMouseActive = useDefinitionStore((state) => state.setMouseActive);

  const noticeActive = useDefinitionStore((state) => state.noticeActive);
  const setNoticeActive = useDefinitionStore((state) => state.setNoticeActive);

  const thesaurus = useThesoStore((state) => state.opentheso);

  const myConcept = thesaurus.find(
    (c) => c.identifier[`#text`] === parseInt(props?.params?.idc)
  );

  const { data: defs } = useQueryDefinitions(`palettes/${couche}`);

  // defs ? console.log(defs) : null;

  // let child = text[1].children[0].data;
  let child = props.children;
  // console.log(text);

  // const [hovered, setHovered] = useState(false);

  function getRelated(related) {
    let urlList = Array.isArray(related)
      ? related.map((r) => r[`@_resource`])
      : [related[`@_resource`]];

    let idList = urlList.map((i) => {
      return parseInt(parseExternalUrl(i).idc);
    });

    let conceptList = thesaurus
      .filter((c) => idList.includes(c.identifier[`#text`]))
      .filter((c) => c.notation)
      .map((c) => {
        let notation = convertNotation(c.notation[`#text`]);
        return {
          ...notation,
          label: c.prefLabel[`#text`],
        };
      });

    return conceptList;
  }

  // myConcept.related && console.log(getRelated(myConcept.related));

  // let listRelated = getRelated(myConcept?.related);

  useEffect(() => {
    if (myConcept.related) {
      let listRelated = getRelated(myConcept.related);
      setRelated(listRelated);
    }
  }, []);

  const handleMouseEnter = (e) => {
    e.stopPropagation();
    setCurrentCouche(couche);
    setNoticeActive(true);
    setMouseActive(true);
    // console.log("over");
    // console.log(hex, child);
    setActiveColor(hex, couche);
    // setHovered(true);
  };

  const handleMouseLeave = (e) => {
    e.stopPropagation();
    // setPreviousColor(hex, couche)

    setActiveColor("000000", couche);
    // console.log("out");
    setNoticeActive(false);
    setMouseActive(false);

    // setHovered(false);
  };

  const handleMouseOver = (e) => {
    e.stopPropagation();
    setNoticeActive(true);
    setMouseActive(true);
    console.log("over");
    console.log(hex, child);
    setActiveColor(hex, couche);
  };

  const handleMouseOut = (e) => {
    e.stopPropagation();
    // setPreviousColor(hex, couche)
    setActiveColor("000000", couche);
    console.log("out");
    setNoticeActive(false);
    setMouseActive(false);
  };

  const handleContextMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    myConcept ? console.log(myConcept) : null;
    // console.log(props);
    // console.log(hex, couche);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItem = (e) => {
    e.stopPropagation();
    // console.log(id, text, couche, hex);

    navigate(`/fragments/${couche}/${hex}`);
  };

  return (
    <>
      <Link
        underline="none"
        // onMouseEnter={() => console.log(`${couche} : ${hex}`)}

        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onContextMenu={handleContextMenu}
        // className="notice-annotation"
        // onMouseOver={handleMouseOver}
        // onMouseOut={handleMouseOut}
      >
        {child}
      </Link>
      <Menu id="menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {hex !== "000000" ? (
          <MenuItem onClick={handleMenuItem}>
            Fragments partageant cette annotation
          </MenuItem>
        ) : (
          <MenuItem onClick={handleClose}>...</MenuItem>
        )}
        {related
          ? related.map((r, i) => (
              <MenuItem
                key={i}
                onClick={() => {
                  navigate(`/fragments/${r.couche}/${r.hex}`);
                }}
                onMouseEnter={() => {
                  setActiveColor(r.hex, r.couche);
                }}
              >
                {r.label}
              </MenuItem>
            ))
          : null}
        {/* <MenuItem onClick={handleClose}>Option 2</MenuItem>
        <MenuItem onClick={handleClose}>Option 3</MenuItem> */}
      </Menu>
    </>

    // <Tooltip key={id} title={couche} arrow leaveDelay={250}>
    //   <a>
    //     <dfn title={`${couche} : ${hex}`}>test</dfn>
    //   </a>
    // </Tooltip>
  );
};

export default TooltipAnnotation;
