import React, { useState } from "react";
import {
  Drawer,
  Box,
  Grid,
  Backdrop,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTreeViewApiRef } from "@mui/x-tree-view";

import CloseIcon from "@mui/icons-material/Close";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import TableDesMatieres from "../../chapitres/tableDesMatieres/tableDesMatieres";
import TexteGeneral from "../../chapitres/texteGeneral/texteGeneral";

const DrawerTocText = (props) => {
  const { open, handleClose } = props;

  const [id, setId] = useState("intro");
  const [selectedItems, setSelectedItems] = useState(null);

  const apiRef = useTreeViewApiRef();
  const navigate = useNavigate();

  const modalStyle = {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    // width: "calc(100% - 5*24px)",
    // height: "calc(100% - 5*24px)",
    width: "600px",
    height: "100%",
    bgcolor: "background.paper",
    // padding: "12px 0px 12px 0px",
    // borderRadius: "12px",
    backgroundColor: "#000000CC",
    transition: "all 0.3s ease-in-out",
  };

  const boxStyle = {
    columnCount: 1,
    columnFill: "auto",
    columnGap: "30px",
    // height: `calc(100% - 100px)`,
  };

  const voletStyle = {
    height: "80vh",

    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#00000050",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "00000050",
      backgroundColor: "#555",
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#888",
    },
  };

  const modalProps = {
    sx: {
      pointerEvents: "none",
      "& .MuiDialog-container": {
        pointerEvents: "none",
      },
      "& .MuiPaper-root": {
        pointerEvents: "auto",
      },
    },
  };

  const StyledBackdrop = styled(Backdrop)`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgb(0.2 0.9 0.3 / 0);
  `;

  const TextDrawer = styled(Drawer)`
    .MuiDrawer-paper {
      background-color: #00000000;
      color: #fff;
    }
  `;

  const TocDrawer = styled(Drawer)`
    .MuiDrawer-paper {
      background-color: #00000000;
      color: #fff;
    }
  `;

  const handleId = (id) => {
    setId(id);
  };

  const handleSelectedItemsChange = (event, itemId) => {
    if (itemId == null) {
      setSelectedItems(null);
    } else {
      setSelectedItems(apiRef.current.getItem(itemId));
      setId(itemId);
      // console.log(itemId);
      navigate(`/tholos/${itemId}`);
    }
  };

  return (
    <>
      <TocDrawer
        open={open}
        onClose={handleClose}
        anchor="left"
        slots={{ backdrop: StyledBackdrop }}
        hideBackdrop
        ModalProps={modalProps}
      >
        <Box style={modalStyle}>
          <Toolbar variant="dense">
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="button">Table des matières</Typography>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <IconButton size="small" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Toolbar>

          <TableDesMatieres
            style={voletStyle}
            setId={setId}
            ref={apiRef}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            handleSelectedItemsChange={handleSelectedItemsChange}
          />
        </Box>
      </TocDrawer>
      <TextDrawer
        open={open}
        onClose={handleClose}
        anchor="right"
        slots={{ backdrop: StyledBackdrop }}
        hideBackdrop
        ModalProps={modalProps}
      >
        <Box style={modalStyle}>
          <Toolbar variant="dense">
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="button">
                Le décor sculpté de la Tholos de Marmaria
              </Typography>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <IconButton size="small" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Toolbar>
          <Grid container direction="row" alignItems="stretch">
            <Grid item>
              <Box sx={{ m: 2 }}>
                <TexteGeneral
                  id={id}
                  handleId={handleId}
                  setId={setId}
                  setSelectedItems={setSelectedItems}
                  boxStyle={boxStyle}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </TextDrawer>
    </>
  );
};

export default DrawerTocText;
