export const fragmentsPM = [
  {
    decor_ID: "petite-metope_34",
    name: "11660",
    position: [0.155, 0.07, 0.03],
    rotation: [0, 0, 0],
    fragments: [],
  },
  {
    decor_ID: "petite-metope_35",
    name: "4408",
    position: [0.156, 0.04, 0],
    rotation: [0, 0, -90],
    fragments: [],
  },
  {
    decor_ID: "petite-metope_36",
    name: "4351-3",
    position: [-0.13, -0.13, -0.015],
    rotation: [0, 0, 0],
    fragments: [],
  },
  {
    decor_ID: "petite-metope_37",
    name: "4025",
    position: [0.05, 0.05, 0],
    rotation: [0, 0, 0],
    fragments: [],
  },
  {
    decor_ID: "petite-metope_38",
    name: "6590",
    title: "Métope à l'hydre de Lerne",
    position: [-0.06, -0.095, 0],
    rotation: [0, 0, 0],
    fragments: [],
  },
  {
    decor_ID: "petite-metope_39",
    name: "11662",
    position: [-0.14, -0.125, 0],
    rotation: [0, 0, 0],
    fragments: [],
  },
  {
    decor_ID: "petite-metope_40",
    name: "937",
    position: [-0.08, 0, 0.01],
    rotation: [0, 0, 0],
    fragments: [],
  },
];
