import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Divider,
  IconButton,
  Box,
  Skeleton,
  Stack,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import NarrowConcept from "../narrowConcept";
import BroadConcept from "../broadConcept";
import RelatedConcept from "../relatedConcept";

import { useNavigate } from "react-router-dom";

import { getParams } from "../../../../outils";

const CardConcept = (props) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleTop = () => {
    let { idc } = getParams(props.broader[`@_resource`]);
    navigate(`./../${idc}`);
  };

  console.log(props);

  const PlaceHolder = () => {
    return null;
  };

  const BoxTags = ({ children }) => (
    <Box
      sx={{
        p: 1,
        minHeight: "140px",
        width: "100%",
        backgroundColor: "#222222",
        radius: "5px",
      }}
    >
      {children}
    </Box>
  );

  return (
    <Card>
      <CardHeader
        title={
          props.prefLabel[`#text`] ? (
            <Typography variant="h5">{props.prefLabel[`#text`]}</Typography>
          ) : null
        }
        subheader={props.broader ? <BroadConcept {...props.broader} /> : null}
        action={
          <>
            {props.broader ? (
              <IconButton onClick={handleTop}>
                <ArrowUpwardIcon />
              </IconButton>
            ) : null}
            <IconButton onClick={handleBack}>
              <ArrowBackIcon />
            </IconButton>
          </>
        }
      />
      <CardContent>
        <Box
          sx={{
            minHeight: "160px",
            width: "100%",
            backgroundColor: "#222222",
            radius: "5px",
            p: 1,
          }}
        >
          {props.definition ? (
            <Typography variant="body2">{props.definition[`#text`]}</Typography>
          ) : null}
        </Box>
      </CardContent>
      <CardActions>
        <Stack spacing={1} sx={{ width: "100%", m: 1 }} direction="row">
          <BoxTags>
            <Typography>Concepts spécifiques</Typography>
            {props.narrower ? (
              Array.isArray(props.narrower) ? (
                props.narrower.map((obj, index) => (
                  <NarrowConcept key={index} {...obj} />
                ))
              ) : (
                <NarrowConcept key="index" {...props.narrower} />
              )
            ) : (
              <PlaceHolder />
            )}
          </BoxTags>

          <BoxTags>
            <Typography>Concepts associés</Typography>
            {props.related ? (
              Array.isArray(props.related) ? (
                props.related.map((obj, index) => (
                  <RelatedConcept key={index} {...obj} />
                ))
              ) : (
                <RelatedConcept key="index" {...props.related} />
              )
            ) : (
              <PlaceHolder />
            )}
          </BoxTags>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default CardConcept;
