import React, { useRef, forwardRef, useMemo } from "react";
import { degToRad } from "three/src/math/MathUtils.js";
import { useGLTF, Detailed, useTexture } from "@react-three/drei";
import { checkIfFileExists, checkIfUrlExists } from "../../../../outils";
import { useNavigate } from "react-router-dom";

const Fragment = forwardRef((props, ref) => {
  const { position, name, active, rotation } = props;
  const navigate = useNavigate();

  const matRef = useRef();
  const meshRef = useRef();
  //   const group = useRef();

  const rotationRAD = rotation.map((angle) => degToRad(angle));

  /**
   *
   *
   */

  let [hasLow3D, hasHigh3D, hasLowTexture, hasHighTexture, hasHighNormal] = [
    `/modeles/${name}/5K/${name}.glb`,
    `/modeles/${name}/${name}.glb`,
    `/modeles/${name}/5K/${name}_base.jpg`,
    `/modeles/${name}/${name}_base.jpg`,
    `/modeles/${name}/${name}_normal.jpg`,
  ].map((url, i) => checkIfUrlExists(url));

  /**
   *
   *
   *
   */

  const highResTexture = useTexture({
    map:
      hasHigh3D && hasHighTexture
        ? `/modeles/${name}/${name}_base.jpg`
        : "/modeles/placeholders/couche_white.png",
    normalMap:
      hasHigh3D && hasHighNormal
        ? `/modeles/${name}/${name}_normal.jpg`
        : "/modeles/placeholders/normal_blank.png",
  });

  highResTexture.map.flipY = false;
  highResTexture.normalMap.flipY = false;

  const lowResTexture = useTexture({
    map:
      hasLow3D && hasLowTexture
        ? `/modeles/${name}/5K/${name}_base.jpg`
        : "/modeles/placeholders/couche_white.png",
  });
  lowResTexture.map.flipY = false;

  /**
   *
   *
   *
   */

  const [lowURL, highURL] = [
    hasLow3D
      ? `/modeles/${name}/5K/${name}.glb`
      : `/modeles/tholos/nike_wf.glb`,
    hasHigh3D
      ? `/modeles/${name}/${name}.glb`
      : `/modeles/${name}/5K/${name}.glb`,
  ];

  const [low, high] = useGLTF([lowURL, highURL]);

  const { nodes: highNodes } = high;
  const { Scene: highScene, ...highMesh } = highNodes;
  const [highMeshName] = Object.keys(highMesh);

  const { nodes: lowNodes } = low;
  const { Scene: lowScene, ...lowMesh } = lowNodes;
  const [lowMeshName] = Object.keys(lowMesh);

  /**
   *
   *
   */

  const highProps = {
    name: name,
    mesh: highNodes[highMeshName],
    visible: true,
    texture: highResTexture,
  };
  const lowProps = {
    name: name,
    mesh: lowNodes[lowMeshName],
    visible: true,
    texture: lowResTexture,
  };

  /**
   *
   *
   *
   */
  const Model = ({ name, mesh, visible, texture }) => {
    return (
      <mesh
        name={`${name}_high-mesh`}
        transparent
        opacity={1}
        castShadow
        receiveShadow
        geometry={mesh.geometry}
        visible={visible}
      >
        <meshStandardMaterial {...texture} />
      </mesh>
    );
  };

  const [LowModel, HighModel] = [lowProps, highProps].map((props, i) => () => {
    return <Model {...props} key={i} />;
  });

  /**
   *
   *
   *
   */

  const handleDoubleClick = (e) => {
    e.stopPropagation();
    console.log("double clicked", active, name);
    if (active) {
      navigate(`/fragments/${name.replaceAll("-", ",")}`);
    }
  };

  useGLTF.preload([lowURL, highURL]);

  return (
    <group
      ref={ref}
      {...props}
      dispose={null}
      position={position}
      rotation={rotationRAD}
      name={name}
      onDoubleClick={handleDoubleClick}
    >
      {hasHigh3D ? (
        <Detailed distances={active ? [0.35, 6] : [0.1, 1]}>
          <HighModel />
          <LowModel />
        </Detailed>
      ) : (
        <LowModel />
      )}
    </group>
  );
});

export default Fragment;
