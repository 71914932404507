import React, { useEffect } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";

const Outils = () => {
  let warning = "red";

  const List = () => {
    return (
      <ul>
        {/* <li>
          <Link to={`/tholos`}>tholos</Link>
        </li>
        <li>
          <Link to={`/fragments`}>fragments</Link>
        </li> */}
        <li>
          <Link to={`/thesaurii`}>thésaurii (intégration Opentheso)</Link>
        </li>
        <li>
          <Link to={`/palettes`}>
            palettes d'encodage (intégration PostgreSQL)
          </Link>
        </li>
        <li>
          <Link to={`/viewer_sansLeva`}>visionneuse 3D de l'encodage</Link>
        </li>

        <li>
          <Link to={`/admin`} style={{ fontWeight: "bold", color: warning }}>
            Outils de vérification{" "}
          </Link>
          ( /!\ blocage du navigateur pendant le calcul)
        </li>
      </ul>
    );
  };

  return (
    <div id="list">
      <List />
    </div>
  );
};

export default Outils;
