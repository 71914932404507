import React from "react";
import { useThree } from "@react-three/fiber";

import { Route, Router } from "wouter";
import useHashLocation from "../../../hooks/useHashLocation";

const RouteScene = (props) => {
  const { name, path, ...others } = props;
  const { scene } = useThree();
  scene.name = `scene-${name}`;

  return (
    <Route path={path} nest>
      {/* <group name={name}>{others.children}</group> */}
      {others.children}
    </Route>
  );
};

const env = import.meta.env.VITE_NODE_ENV;

const RouteProd = (props) => {
  return (
    <Router hook={useHashLocation}>
      <RouteScene {...props} />
    </Router>
  );
};

const RouteDev = (props) => {
  return (
    <Router>
      <RouteScene {...props} />
    </Router>
  );
};

const Routes = {
  development: RouteDev,
  production: RouteProd,
  //   development: <RouteDev />,
  //   production: <RouteProd />,
};

const CurrentRoute = (props) => {
  let MyRoute = Routes[env];
  return <MyRoute {...props} />;
};

export default CurrentRoute;
