export const fragmentsGM = [
  {
    decor_ID: "grande-metope_36",
    name: "4183-b",
    title: "Métope au pied droit",
    position: [0, 0.01, -0.01],
    rotation: [0, 0, 0.5],
    fragments: [],
  },
  {
    decor_ID: "grande-metope_39",
    name: "4226",
    title: "Métope à la colonne",
    position: [0, 0, 0.057],
    rotation: [0, 0, 0],
    fragments: [],
    reconstitution: {
      status: true,
      position: [0, 0, -0.005],
      rotation: [0, 0, 0],
    },
  },
  {
    decor_ID: "grande-metope_2",
    name: "2856",
    title: "Métope à la croupe chevaline",
    position: [-0.17, 0.105, -0.0],
    rotation: [0, 0, 0],
    fragments: [],
  },
  {
    decor_ID: "grande-metope_37",
    name: "4314-1",
    title: "Amazone tirant de l'arc",
    position: [0.005, 0.04, 0.025],
    rotation: [0, 0, 0],
    fragments: [],
    complementPlaque: true,
  },
  {
    decor_ID: "grande-metope_38",
    name: "3168",
    title: "Métope au cheval cabré",
    position: [0.0, 0.01, 0.07],
    rotation: [0, 0, 0],
    fragments: [],
  },
  {
    decor_ID: "grande-metope_1",
    name: "4183-a",
    title: "Métope à la pelta",
    position: [0.13, 0.02, 0.02],
    rotation: [0, 0, 0],
    fragments: [],
  },
  {
    decor_ID: "grande-metope_35",
    name: "4476-11",
    title: "Métope au duel amazonomachique",
    position: [0, -0.05, 0.08],
    rotation: [0, 0, 0],
    fragments: [],
  },
  {
    decor_ID: "grande-metope_34",
    name: "4351-1",
    title: "Métope au duel amazonomachique 2",
    position: [0, 0, 0],
    rotation: [0, 0, 0],
    fragments: [
      {
        name: "4351-1",
        position: [-0.141, 0.001, 0.028],
        rotation: [8.411, 6.626, 3.87],
      },
      {
        name: "4335-1",
        position: [0.115, -0.161, 0.035],
        rotation: [2.505, -1.031, -35.045],
      },
    ],
    reconstitution: {
      status: true,
      position: [0, 0, -0.045],
      rotation: [0, 0, 0],
    },
  },
];
