import React from "react";
import { useGLTF, Detailed, useTexture } from "@react-three/drei";

const Complement = ({ name, position }) => {
  const { nodes, materials } = useGLTF(`/modeles/${name}/${name}_plaque.glb`);
  const { Scene, ...mesh } = nodes;
  const [meshName] = Object.keys(mesh);
  //   const [matName] = Object.keys(materials);

  return (
    <mesh
      name={`${name}_complement-plaque`}
      transparent
      opacity={1}
      castShadow
      receiveShadow
      geometry={nodes[meshName].geometry}
      //   material={materials[matName]}
      visible={true}
      position={position}
    >
      <meshStandardMaterial name={`mat`} transparent={true} opacity={0.75} />
    </mesh>
  );
};

export default Complement;
