import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";

const useUiStore = create((set) => ({
  random: false,
  updateRandom: () => {
    set((state) => ({ random: !state.random }));
  },
  paramsTholos: {
    updatedRandom: { value: false, label: "Placement aléatoire", index: 0 },
    decor: {
      value: true,
      label: "Décor sculpté",
      index: 0,
      type: "switch",
    },
    reconstitution: {
      value: true,
      label: "Architecture",
      index: 1,
      type: "switch",
    },
    anastyloseSite: {
      value: true,
      label: "Etat actuel",
      index: 2,
      type: "switch",
    },
    entablementMusee: {
      value: false,
      label: "Anastylose musée",
      index: 3,
      type: "aucun",
    },
    pointcloud: {
      value: false,
      label: "Nuage de points",
      index: 4,
      type: "switch",
    },
  },
  paramsFragments: {
    scaled: { value: false, label: "mettre à l'échelle", index: 0 },
    animated: { value: true, label: "animer les fragments", index: 1 },
    lit: { value: true, label: "éclairage dynamique", index: 2 },
    numero: { value: false, label: "numéro d'inventaire", index: 3 },
    legend: { value: false, label: "légende au survol", index: 4 },
    stats: { value: false, label: "statistiques (prov)", index: 5 },
  },
  paramsFragment: {
    diffuse: { value: 1.0, label: "texture", index: 0, type: "slider" },
    normale: { value: 0.5, label: "micro-relief", index: 1, type: "slider" },
    lit: {
      value: false,
      label: "éclairage dynamique",
      index: 2,
      type: "switch",
    },
    reframe: { value: false },
    annotationsOn: { value: true },
  },
  setParamsTholos: (paramsTholos) =>
    set((state) => ({ ...state, paramsTholos })),
  setParamsFragments: (paramsFragments) =>
    set((state) => ({ ...state, paramsFragments })),
  setParamsFragment: (paramsFragment) =>
    set((state) => ({ ...state, paramsFragment })),

  paramsPagination: { grid: 1, page: 1 },
  setParamsPagination: (paramsPagination) =>
    set((state) => ({ ...state, paramsPagination })),
  cameraFragments: { fov: 5, near: 0.01, far: 60, position: [0, 0, 40] },
  setCameraFragments: (cameraFragments) =>
    set((state) => ({ ...state, cameraFragments })),
  paramsTexte: {
    drawerTexteOpen: false,
    drawerTocOpen: false,
  },
}));

if (import.meta.env.VITE_NODE_ENV === "development") {
  mountStoreDevtool("UiStore", useUiStore);
}

export default useUiStore;
