import React, { useState, useEffect } from "react";
import { Box, Fab, Badge, Tooltip } from "@mui/material";

import TuneIcon from "@mui/icons-material/Tune";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import RefreshIcon from "@mui/icons-material/Refresh";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationOffIcon from "@mui/icons-material/LocationOff";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocationOffOutlinedIcon from "@mui/icons-material/LocationOffOutlined";

import NotesIcon from "@mui/icons-material/Notes";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DescriptionIcon from "@mui/icons-material/Description";

import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";

import useUiStore from "../../../../stores/useUiStore";
import useFetch from "../../../hooks/useFetch";

const FragmentFabs = (props) => {
  const {
    handleSettings,
    handleFitToSphere,
    toogleAnnotations,
    setOpenNotice,
    openNotice,
    annotationsOn,
    setOpenGallery,
    openGallery,
    setOpenGalleryModal,
    openGalleryModal,
    hasAnnotations2D,
  } = props;

  const [triggerReframe, setTriggerReframe] = useState(false);
  const paramsFragment = useUiStore((state) => state.paramsFragment);

  const { data: config } = useFetch({ url: "/json/config.json" });

  const backgroundColor = "#FFFFFF66";

  const handleFullScreenChange = () => {
    Boolean(document.fullscreenElement)
      ? document.exitFullscreen()
      : document.body.requestFullscreen();
  };

  const handleReframe = () => {
    setTriggerReframe(!triggerReframe);
    useUiStore.setState(
      (state) => (state.paramsFragment.reframe = { value: triggerReframe })
    );
  };

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }} id="fabs">
      <Tooltip title="Réglages" arrow>
        <Fab
          size="small"
          // title="Réglages"
          onClick={handleSettings(true)}
          sx={{ backgroundColor: backgroundColor }}
        >
          <TuneIcon />
        </Fab>
      </Tooltip>

      <Tooltip
        arrow
        title={
          Boolean(document.fullscreenElement)
            ? "Sortir du plein écran"
            : "Plein écran"
        }
      >
        <Fab
          size="small"
          // title={
          //   Boolean(document.fullscreenElement)
          //     ? "Sortir du plein écran"
          //     : "Plein écran"
          // }
          sx={{ backgroundColor: backgroundColor }}
          onClick={handleFullScreenChange}
        >
          {Boolean(document.fullscreenElement) ? (
            <FullscreenExitIcon />
          ) : (
            <FullscreenIcon />
          )}
        </Fab>
      </Tooltip>
      <Tooltip title="Recentrer" arrow>
        <Fab
          size="small"
          // title="Recentrer"
          onClick={handleFitToSphere}
          sx={{ backgroundColor: backgroundColor }}
        >
          <CenterFocusWeakIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Notice" arrow>
        <Fab
          disabled={openGalleryModal || openGallery}
          size="small"
          // title="Recentrer"
          onClick={() => setOpenNotice(!openNotice)}
          sx={{ backgroundColor: openNotice ? "#FFFFFF" : backgroundColor }}
        >
          <DescriptionOutlinedIcon />
          {/* {openNotice ? <DescriptionIcon /> : <DescriptionOutlinedIcon />} */}
        </Fab>
      </Tooltip>
      <Tooltip title="Galerie Volet" arrow>
        <Fab
          disabled={openNotice || openGalleryModal || !hasAnnotations2D}
          size="small"
          // title="Recentrer"
          onClick={() => setOpenGallery(!openGallery)}
          sx={{
            backgroundColor: openGallery ? "#FFFFFF" : backgroundColor,
          }}
        >
          <CollectionsOutlinedIcon />
        </Fab>
      </Tooltip>

      {config?.modeAdmin && (
        <>
          <Tooltip title="Annotations" arrow>
            <Fab
              size="small"
              // title="Recentrer"
              onClick={toogleAnnotations}
              sx={{ backgroundColor: backgroundColor }}
            >
              {annotationsOn ? (
                <LocationOnOutlinedIcon />
              ) : (
                <LocationOffOutlinedIcon />
              )}
            </Fab>
          </Tooltip>

          {/* <Tooltip title="Galerie Modal" arrow>
            <Fab
              disabled={openNotice || openGallery || !hasAnnotations2D}
              size="small"
              // title="Recentrer"
              onClick={() => setOpenGalleryModal(!openGalleryModal)}
              sx={{
                backgroundColor: openGalleryModal ? "#FFFFFF" : backgroundColor,
              }}
            >
              <PhotoOutlinedIcon />
            </Fab>
          </Tooltip> */}
        </>
      )}
    </Box>
  );
};

export default FragmentFabs;
