import React, { useState, useEffect, useMemo } from "react";
import { XMLParser } from "fast-xml-parser";
import * as jp from "jsonpath";

import useModelStore from "../../../stores/useModelStore";

import Bibliographie from "./bibliographie";
import Head from "./head";
import Title from "./title";
import BibliographieAccordion from "./bibliographieAccordion";
import Section1 from "./section1";
import {
  Drawer,
  Box,
  Typography,
  Stack,
  Avatar,
  Badge,
  Tab,
  Tabs,
} from "@mui/material";

import useParseNotice from "../../hooks/useParseNotice";
import useFetch from "../../hooks/useFetch";

import { checkIfUrlExists } from "../../../outils";

import xmlParserOptions from "./common/xmlParserOptions";

const NoticeMarcadeFastXmlParser = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { fragment } = props;

  const [xml, setXml] = useState(null);

  const parser = new XMLParser(xmlParserOptions);
  const { data: config } = useFetch({ url: "/json/config.json" });

  // const { isSuccess, isLoading, isError, xml: myXml } = useParseNotice(numero);
  // isSuccess ? console.log(myXml) : null;

  /**
   * LOADER
   */

  useEffect(() => {
    let numero = checkIfUrlExists(`/xml/notices/${fragment}.xml`)
      ? fragment
      : "placeholder";

    var xhr = new XMLHttpRequest();
    xhr.addEventListener("load", () => {
      let parsedData = parser.parse(xhr.response);
      setXml(parsedData);
    });
    // xhr.open("GET", `/xml/marcade_${numero}.xml`);
    xhr.open("GET", `/xml/notices/${numero}.xml`);
    xhr.send();
  }, [fragment]);

  /**
   * JSONPATH SYNTAX TRIALS
   */
  // xml ? (titreNotice = jp.query(xml, `$..div[?(@.head=="Titre notice")]`)) : null;
  // xml ? (texteNotice = jp.query(xml, `$..div[?(@.head=="Texte notice")]`)) : null;
  // xml ? (section2 = jp.query(xml, `$..div[?(@.@_type=="section2")]`)) : null;

  // xml ? console.log(xml.TEI.text.body.div[0].div[1].p[2][`#text`]) : null;

  let title;
  let section1;
  let section1Lecteur;
  let bibliography;
  let notice;
  let hasRestitution;

  let annotations3D;

  xml ? (title = jp.query(xml, `$..[?(@.@_rend=="title-main")]`)) : null;

  xml ? (section1 = jp.query(xml, `$..[?(@.@_type=="section1")]`)) : null;

  xml
    ? (notice = jp.query(
        xml,
        `$..[?(@.@_type=="section1" && @.head=="Notice Marcadé")]`
      ))
    : null;
  xml
    ? (bibliography = jp.query(xml, `$..[?(@.@_type=="bibliography")]`))
    : null;

  xml
    ? (annotations3D = jp.query(
        xml,
        `$..[?(@.@_type=="section2" && @.head=="Annotations 3D")]`
      ))
    : null;

  xml && config && config?.modeAdmin === false
    ? (section1 = section1.filter((s) => s.head !== "État actuel"))
    : section1;

  // console.log(section1, section1Lecteur);

  const Section = ({ sectionName }) => {
    return xml && section1
      ? section1.map(
          (section, index) =>
            section.head === `${sectionName}` && (
              <Section1 key={section[`@_xml:id`]} {...section} />
            )
        )
      : null;
  };

  const Biblio = () => {
    return xml && bibliography
      ? bibliography.map((bib, index) => (
          <BibliographieAccordion key={bib[`@_xml:id`]} {...bib} />
        ))
      : null;
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return <div>{value === index && <Box>{children}</Box>}</div>;
  };

  return (
    config && (
      <Box sx={{ p: 2 }} id="notice-marcade">
        {xml && title ? <Head text={title[0][`#text`]} variant="h5" /> : null}

        <Tabs value={value} onChange={handleChange}>
          {xml &&
            section1.map((section, index) => (
              <Tab key={index} label={section.head} value={index} />
            ))}
          {xml && <Tab label="Bibliographie" value={section1.length} />}
        </Tabs>

        {xml &&
          section1.map((section, index) => (
            <TabPanel key={index} value={value} index={index}>
              <Section sectionName={section.head} />
            </TabPanel>
          ))}

        {xml && (
          <TabPanel value={value} index={section1.length}>
            <Biblio />
          </TabPanel>
        )}
      </Box>
    )
  );
};

export default NoticeMarcadeFastXmlParser;
