import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Toolbar,
  Typography,
  Modal,
  Grid,
  Backdrop,
  IconButton,
  Fade,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useTreeViewApiRef } from "@mui/x-tree-view";

import TableDesMatieres from "../../chapitres/tableDesMatieres/tableDesMatieres";
import TexteGeneral from "../../chapitres/texteGeneral/texteGeneral";

// const MyDrawer = styled(Drawer)(() => ({
//   overflow: "hidden",
//   "& .MuiModal-backdrop": {
//     backgroundColor: "rgba(0,0,0,0.0)",
//   },
// }));

// const MyModal = styled(Modal)(() => ({
//   "& .MuiModal-backdrop": {
//     backgroundColor: "rgba(0,0,0,0.5)",
//   },
// }));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 5*24px)",
  height: "calc(100% - 5*24px)",
  bgcolor: "background.paper",
  padding: "12px 0px 12px 0px",
  borderRadius: "12px",
  backgroundColor: "#000000BB",
  transition: "all 0.3s ease-in-out",
};

const voletStyle = {
  height: "80vh",

  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#00000050",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "00000050",
    backgroundColor: "#555",
    borderRadius: "2px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#888",
  },
};

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0.2 0.9 0.3 / 0);
`;

const ModalTocText = (props) => {
  const { open, handleClose } = props;

  const [id, setId] = useState("intro");
  const [selectedItems, setSelectedItems] = useState(null);

  const apiRef = useTreeViewApiRef();
  const navigate = useNavigate();

  const handleSelectedItemsChange = (event, itemId) => {
    if (itemId == null) {
      setSelectedItems(null);
    } else {
      setSelectedItems(apiRef.current.getItem(itemId));
      setId(itemId);
      // console.log(itemId);
      navigate(`/tholos/${itemId}`);
    }
  };

  const boxStyle = {
    columnCount: 3,
    columnFill: "auto",
    columnGap: "30px",
    height: `calc(100% - 100px)`,
  };

  const handleId = (id) => {
    setId(id);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        // slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            <Toolbar variant="dense">
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="button">
                  Le décor sculpté de la Tholos de Marmaria
                </Typography>
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <IconButton size="small" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Toolbar>
            <Grid container direction="row" alignItems="stretch">
              <Grid item xs={4}>
                <Box sx={{ m: 1 }}>
                  <TableDesMatieres
                    style={voletStyle}
                    setId={setId}
                    ref={apiRef}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    handleSelectedItemsChange={handleSelectedItemsChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ m: 1, pr: 4, ...voletStyle }}>
                  <TexteGeneral
                    style={voletStyle}
                    boxStyle={boxStyle}
                    id={id}
                    handleId={handleId}
                    setId={setId}
                    setSelectedItems={setSelectedItems}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalTocText;
