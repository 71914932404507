import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Stack, Grid, Button } from "@mui/material";

import { useRoute, useLocation, Route, Router } from "wouter";

import * as jp from "jsonpath";

import useStyledXML from "../../hooks/useStyledXML";
import { xmlParserOptions as biblioOptions } from "../bibliographieGenerale/xmlParserOptions";
import { xmlParserOptions as texteOptions } from "./xmlParserOptions";

import { truncateSentence } from "../../../outils";

import Paragraphe from "../notices/paragraphe";

const TexteGeneral = (props) => {
  const { id, setId, setSelectedItems, boxStyle } = props;
  const [text, setText] = useState([]);

  const [fragments, setFragments] = useState([]);

  const handleId = (id) => {
    setId(id);
    setSelectedItems(id);
  };

  let params = { id: id };

  const fragOptions = {
    ignoreAttributes: false,
    attributeNamePrefix: "",
    trim: true,
    stopNodes: ["*.head", "*.label"],
    processEntities: true,
    ignoreDeclaration: true,
    ignorePiTags: true,
    removeNSPrefix: true,
    isArray: (name, jpath, isLeafNode, isAttribute) => {
      if (["children", "div", "p", "ref"].indexOf(name) !== -1) return true;
    },
  };

  const txt = useStyledXML({
    xml: "texteGeneral",
    xslt: "tableOfContent",
    expr: `$..[?(@.type=="section1")]`,
    parserOptions: texteOptions,
  });

  const bib = useStyledXML({
    xml: "bibliographie",
    xslt: "tableOfContent",
    expr: `$..body..bibl[*]`,
    parserOptions: biblioOptions,
  });

  const frag = useStyledXML({
    xml: "texteGeneral",
    xslt: "tableOfContent",
    // expr: `$..body..ref[*].target`,
    // expr: `$..[?(@.subtype=="${params?.id}")]..ref[*].target`,
    // expr: `$..[?(@.subtype)]..ref[*]`,
    expr: `$..body..[?(@.subtype)]`,
    parserOptions: fragOptions,
  });

  // console.log(params?.id, frag);

  useEffect(() => {
    if (params?.id && txt && id !== "bibliographie") {
      let [res] = jp.query(txt, `$..[?(@.subtype=="${params?.id}")]`);

      setText(res);
    }
  }, [params?.id, txt, bib]);

  useEffect(() => {
    if (params?.id && frag && id !== "bibliographie") {
      let res = jp.query(
        frag,
        `$..[?(@.subtype=="${params?.id}")]..ref[*].target`
      );
      console.log([...new Set(res)], id);
      // setFragments(res);
    }
  }, [id, frag]);

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* <Typography variant="h6">{text?.head}</Typography> */}
      {id === "bibliographie" ? (
        <Typography
          variant="h6"
          component="div"
          dangerouslySetInnerHTML={{ __html: "Bibliographie" }}
        />
      ) : (
        <Typography
          variant="h6"
          component="div"
          dangerouslySetInnerHTML={{ __html: text?.head }}
        />
      )}

      {/* <div dangerouslySetInnerHTML={{ __html: text?.head }} /> */}
      {/* <Stack direction="column"> */}
      {/* <Grid container>
          <Grid item xs={5}>
            {text?.p?.map((p, i) => (
              <Paragraphe key={i} id={p.id} text={p[`#text`]} />
            ))}
          </Grid>
        </Grid> */}
      <Box
        // style={{
        //   columnCount: 3,
        //   columnFill: "auto",
        //   columnGap: "30px",
        //   height: `calc(100% - 100px)`,
        // }}
        style={boxStyle}
      >
        {id === "bibliographie"
          ? bib?.map((p, i) => (
              <Paragraphe key={i} id={p.id} text={p[`#text`]} />
            ))
          : text?.p?.map((p, i) => (
              <Paragraphe key={i} id={p.id} text={p[`#text`]} section={id} />
            ))}

        {/* {id !== "bibliographie"
          ? text?.p?.map((p, i) => (
              <Paragraphe key={i} id={p.id} text={p[`#text`]} />
            ))
          : text &&
            text?.map((p, i) => (
              <Paragraphe key={i} id={p.id} text={p[`#text`]} />
            ))} */}
      </Box>
      <Stack direction="row" spacing={2}>
        {text?.div &&
          text?.div.map((div, i) => (
            <Button key={i} size="small" onClick={() => handleId(div.subtype)}>
              {truncateSentence(div.head, 4)}
            </Button>
          ))}
      </Stack>
    </Stack>
  );
};

export default TexteGeneral;
