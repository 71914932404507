import React, { useState, useEffect } from "react";

import useHistoryBack from "../hooks/useHistoryBack";
import useUiStore from "../../stores/useUiStore";

import Header from "./header/header";
import ModalTocText from "./modalTocText/modalTocTex";
import DrawerToc from "./drawerToc/drawerToc";
import DrawerTocText from "./drawerTocText/drawerTocText";
import Plan from "../plan/plan";

const UI = () => {
  const [open, setOpen] = useState(false);

  // const paramsTexte = useUiStore((state) => state.paramsTexte);
  const handleOpen = () => {
    setOpen(true);
    // useUiStore.setState({
    //   paramsTexte: { ...paramsTexte, drawerTexteOpen: true },
    // });
  };
  const handleClose = () => {
    setOpen(false);
    // useUiStore.setState({
    //   paramsTexte: { ...paramsTexte, drawerTexteOpen: false },
    // });
  };
  const back = useHistoryBack();

  return (
    <>
      <Plan />
      <Header handleOpen={handleOpen} />
      {/* <ModalTocText open={open} handleClose={handleClose} /> */}
      {/* <DrawerToc open={open} handleClose={handleClose} /> */}
      <DrawerTocText open={open} handleClose={handleClose} />
    </>
  );
};

export default UI;
