import React from "react";
import * as THREE from "three";
import { useLoader } from "@react-three/fiber";
import { RGBELoader } from "three-stdlib";

import { ContactShadows, Environment } from "@react-three/drei";

function Lights() {
  const DirectLight = (props) => {
    const { intensity } = props;
    return (
      <directionalLight
        castShadow
        shadow-mapSize={[1024, 1024]}
        shadow-bias={0.001}
        shadow-normalBias={0.15}
        position={[-8, 8, 8]}
        intensity={intensity}
      >
        <orthographicCamera
          attach="shadow-camera"
          args={[-6, 6, 6, -6, 4, 25]}
        />
      </directionalLight>
    );
  };

  const MyEnvironment = (props) => {
    const { rotation, blur, intensity } = props;
    const texture = useLoader(RGBELoader, "/images/hdr/venice_sunset_500.hdr");
    return (
      <Environment background={false} blur={blur}>
        <color attach="background" args={["#000"]} />
        <mesh scale={50} rotation={rotation}>
          <sphereGeometry />
          <meshBasicMaterial
            transparent
            opacity={intensity}
            map={texture}
            side={THREE.BackSide}
            toneMapped={false}
          />
        </mesh>
      </Environment>
    );
  };

  return (
    <group name="Lights">
      <DirectLight intensity={0.25} />

      <ambientLight intensity={0.05} />

      <MyEnvironment
        rotation={[0, Math.PI * 1.2, 0]}
        blur={2}
        intensity={0.75}
      />
    </group>
  );
}

export default Lights;
