import checkIfUrlExists from "./checkIfUrlExists";
import { getPalette } from "../components/CanvasElement";

const layers = [
  { label: "iconographie", value: "iconographie", code: "00" },
  { label: "matérialité", value: "materialite", code: "ff" },
];

function getFragmentsAnnotations(selection) {
  async function getFragments() {
    try {
      const res = await fetch(
        `${import.meta.env.VITE_SERVER_API}/fragments/gltf`
      )
        .then((response) => response.json())
        .then((fragments) => fragments.map((obj) => obj.numero_inventaire_3d));

      return res;
    } catch (error) {
      console.error("pb");
    }
  }

  function cleanUp(id, delay) {
    window.setTimeout(() => {
      let elem = document.getElementById(id);
      elem.remove();
    }, delay);
  }

  async function setCanvas(image, filename, layer) {
    const placeholder = {};
    placeholder.canvas = document.createElement("canvas");

    placeholder.image = image;
    placeholder.canvas.id = `couche_${filename}_${layer}`;
    placeholder.canvas.width = image.width;
    placeholder.canvas.height = image.height;

    placeholder.context = placeholder.canvas.getContext("2d", {
      willReadFrequently: true,
    });

    placeholder.context.drawImage(
      placeholder.image,
      0,
      0,
      image.width,
      image.height
    );

    let code = layers.filter((l) => l.value === layer).map((l) => l.code);
    let palette = getPalette(placeholder.context, placeholder.canvas.width).map(
      (pal) => `#${pal}${code}`
    );

    return { [`${filename}`]: palette };
  }

  /**
   * LOAD IMAGES
   */

  const loadImage = (imageSrc) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        resolve(image);
        reject(new Error("pas de couche"));
      };
      image.src = imageSrc;
    });

  const getImages = async (layer) => {
    let couchesParFragment = {};
    let couches = {};
    let resultat = {};

    let imgs = await getFragments();

    let imgsList = [];
    if (!selection) {
      imgsList = imgs;
    } else {
      // console.log(selection, imgs);

      imgsList = imgs.filter((i) => i === `${selection.replace(",", "-")}`);
    }

    for (const imageUrl of imgsList) {
      let url = `/modeles/${imageUrl}/${imageUrl}_${layer}.png`;

      if (checkIfUrlExists(url)) {
        // couchesParFragment = {
        //   ...couchesParFragment,
        //   [`${imageUrl}`]: { ...couches, [`${layer}`]: true },
        // };

        const image = await loadImage(url);
        let paletteFragment = await setCanvas(image, imageUrl, layer);
        resultat = { ...resultat, ...paletteFragment };
      }
    }
    // console.log(couchesParFragment);

    return resultat;
  };

  async function returnPalettes() {
    let iconographie = await getImages("iconographie");
    let materialite = await getImages("materialite");
    return { iconographie, materialite };
  }

  let output = returnPalettes();

  return output;
}

export default getFragmentsAnnotations;
