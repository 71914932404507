import React from "react";
import { useGLTF } from "@react-three/drei";
import { fetchUrl } from "../../../../outils";
import { use } from "react";

const Reconstitution = ({ decor_ID, positionSupport, reconstitution }) => {
  const { nodes } = useGLTF(
    `/modeles/metopes/${decor_ID}/${decor_ID}_reconstitution.glb`
  );

  const nodeNames = Object.keys(nodes);
  const myScene = nodeNames.find((n) => nodes[n].type === "Group");
  const myMeshes = nodes[myScene].children;

  const [x, y, z] = positionSupport;
  const [tx, ty, tz] = reconstitution.position;

  //   useGLTF.preload(`/modeles/metopes/${decor_ID}/reconstitution.glb`);

  return (
    <group
      name="reconstitution"
      position={[x + tx, -y + ty, z + tz]}
      visible={true}
    >
      {myMeshes.map((m, i) => (
        <mesh
          key={i}
          transparent
          opacity={1}
          castShadow
          receiveShadow
          geometry={m.geometry}
          visible={true}
          position={m.position}
          rotation={m.rotation}
        >
          <meshStandardMaterial />
        </mesh>
      ))}
    </group>
  );
};

export default Reconstitution;
