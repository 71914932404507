import React, { useEffect } from "react";

const useKeys = (key, action) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === key) {
        action();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [key, action]);
};

export default useKeys;
