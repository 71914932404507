import React, { useEffect, useState } from "react";
import { Toolbar, Box } from "@mui/material";

import useThesoStore from "../../../stores/useThesoStore";
import useAnnotationStore from "../../../stores/useAnnotationStore";

import { getAnnotedFragments } from "../../../outils";

import TagConcept from "./ui/tagConcept";

const Thesaurus = (props) => {
  const { theme } = props;
  const [data, setData] = useState(null);

  let myTheso = useThesoStore((state) => state.thesaurii[`${theme.value}`]);

  let myAnnotations = useAnnotationStore(
    (state) => state.annotationsParFragment[`${theme.value}`]
  );

  useEffect(() => {
    setData(myTheso);
  }, [myTheso.length]);

  // const getAnnotedFragments = (notation) => {
  //   return Object.keys(myAnnotations).filter((frag) =>
  //     myAnnotations[frag].includes(notation)
  //   );
  // };

  return data ? (
    <>
      <Toolbar variant="dense" />
      <Box
        sx={{
          position: "absolute",

          top: "200px",
        }}
      >
        {data.map((concept, index) => (
          <TagConcept
            key={index}
            {...concept}
            down={true}
            fragments={
              concept.notation
                ? getAnnotedFragments(concept.notation[`#text`], myAnnotations)
                : null
            }
          />
        ))}
      </Box>
    </>
  ) : null;
};

export default Thesaurus;
