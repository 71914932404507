import React, { useEffect, useRef, Suspense, useState } from "react";
import parse, { attributesToProps, domToReact } from "html-react-parser";
import {
  checkUrl,
  getOpentheso,
  parseExternalUrl,
  convertNotation,
} from "../../../outils";
import { useRoute, useLocation, Route } from "wouter";
import useHashLocation from "../../hooks/useHashLocation.jsx";

import {
  Drawer,
  Box,
  Typography,
  Divider,
  Stack,
  Avatar,
  Badge,
  Tooltip,
} from "@mui/material";

import {
  TooltipTheso,
  TooltipArchimageIIIF,
  TooltipAnnotation,
  TooltipNote,
  TooltipFragment,
} from "./ui";

// import options from "./common/options.jsx";

const Paragraphe = (props) => {
  const { text, id } = props;

  const [, setLocation] = useLocation();
  const [hashLoc, hashNav] = useHashLocation();

  const env = import.meta.env.VITE_NODE_ENV;

  const options = {
    replace(domNode) {
      let { attribs, children, name } = domNode;

      if (!attribs) {
        return;
      }

      if (name === "idno") {
        return <span id={name}>{domToReact(children, options)}</span>;
      }

      if (name === "ref") {
        if (attribs.target && checkUrl(attribs.target)) {
          let url = attribs.target.split("//")[1];
          let root = url.split("/");
          let domain = url.split(".")[0];
          switch (domain) {
            case "opentheso": {
              let urlParams = new URLSearchParams(attribs.target.split("?")[1]);
              let params = Object.fromEntries(urlParams);

              return (
                // <Suspense fallback={null}>
                <TooltipTheso
                  idc={params.idc}
                  idt={params.idt}
                  text={domToReact(children, options)}
                  target={attribs.target}
                />
                // </Suspense>
              );
              break;
            }
            case "archimage": {
              let urlParams = new URLSearchParams(attribs.target.split("?")[1]);
              let params = Object.fromEntries(urlParams);
              // console.log(params);

              // let urlParamsSlash = root[1];
              // console.log(root[1]);

              if (params)
                switch (params.kroute) {
                  case "fiche_publique": {
                    return (
                      <mark className="archimage-fiche">
                        {domToReact(children, options)}
                      </mark>
                    );
                  }
                  case "fiche_document": {
                    return (
                      <mark className="archimage-fiche">
                        {domToReact(children, options)}
                      </mark>
                    );
                  }
                  case "recuperer_modele_3d": {
                    return (
                      <mark className="archimage-3d">
                        {domToReact(children, options)}
                      </mark>
                    );
                  }
                  case "image_request_iiif": {
                    // console.log(attribs);
                    return (
                      <TooltipArchimageIIIF
                        id={params.id}
                        text={domToReact(children, options)}
                        target={attribs.target}
                      />
                    );
                  }
                }
              if (root[1] === "image_request_iiif") {
                // console.log(children);
                return (
                  <TooltipArchimageIIIF
                    id={root[2]}
                    text={domToReact(children, options)}
                    target={attribs.target}
                  />
                );
              }

              break;
            }
            case "tholos": {
              let route = attribs.target.split("#")[1];
              let section = route.split("/");
              // let { type } = attribs;
              switch (attribs?.type) {
                case "monument": {
                  let elem = route.split("/");
                  let last = elem[elem.length - 1];
                  return (
                    <mark className="monument-3d">
                      {domToReact(children, options)}
                    </mark>
                  );
                }
              }

              switch (section[1]) {
                case "fragments": {
                  let fragment = section[section.length - 1];
                  return (
                    <TooltipFragment
                      id={fragment}
                      text={domToReact(children, options)}
                      fragment={fragment}
                      section={props?.section}
                    />
                  );
                }
              }

              // utiliser useHashRouter...

              // const handleClick = (e) => {
              //   e.preventDefault();
              //   setLocation(hashNav(route));
              // };

              return (
                <span>
                  <a href={route}>
                    <mark className="fragment-3d">
                      {domToReact(children, options)}
                    </mark>
                  </a>
                </span>
              );

              //console.log(attribs);
              // faire une tooltip ?
              // break;
            }
            case "doi": {
              return (
                <>
                  <br></br>
                  <span>
                    <a href={attribs.target} target="_blank" className="doi">
                      {attribs.target}
                    </a>
                  </span>
                </>
              );
            }
          }
        }

        return (
          <span style={{ color: "#C2A074" }}>
            <a href={attribs.target} target="_blank">
              <mark
                style={{
                  color: "#C2A074",
                  backgroundColor: "hsla(0, 0%, 100%, 0)",
                }}
              >
                {domToReact(children, options)}
              </mark>
            </a>
          </span>
        );
      }

      if (name === "hi") {
        switch (attribs.rend) {
          case "italic":
            return <em>{domToReact(children, options)}</em>;

          case "bold":
            return <strong>{domToReact(children, options)}</strong>;

          case "small-caps":
            return (
              <span className="siecle">{domToReact(children, options)}</span>
            );
        }
      }
      if (name === "index") {
        // console.log("trouvé index", attribs);
        switch (attribs.indexname) {
          case "Tholos": {
            let codes = attribs.rendition.split(" ");
            let code = codes[codes.length - 1] || "#00000000";

            let { couche, hex } = convertNotation(code);

            let params = attribs.source
              ? parseExternalUrl(attribs.source)
              : null;

            return (
              <TooltipAnnotation
                id={attribs.rendition}
                couche={couche}
                hex={hex}
                params={params}
                // text={children}
              >
                {domToReact(children, options)}
              </TooltipAnnotation>

              // <span>
              //   <dfn title={`${couches[couche]} : ${hex}`}>
              //     {domToReact(children, options)}
              //   </dfn>
              // </span>
            );
          }
        }
      }
      if (name === "bibl") {
        console.log("biblio :", children);
        return <span>{domToReact(children, options)}</span>;
      }

      if (name === "term") {
        switch (attribs.type) {
          case "orig":
            return (
              <mark className="notice-annotation">
                {domToReact(children, options)}
              </mark>
            );
          default:
            return <span></span>;
        }
      }

      if (name === "note") {
        // console.log("note :", children, attribs);
        return (
          <TooltipNote
            id={attribs[`xml:id`]}
            note={attribs.n}
            data={children?.[1].children?.[0].data}
          />
        );
      }
    },
  };

  let parsedText = parse(text, options);

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <Typography
      variant="body2"
      id={id}
      align="justify"
      paragraph
      onContextMenu={handleContextMenu}
    >
      {parsedText}
    </Typography>
  );
};

export default Paragraphe;
